<template>
	<v-card max-width="800" class="mx-auto mt-5" rounded="xl">
		<v-card-title>{{ $t('settings.changePassword') }}</v-card-title>
		<v-card-text>
			<v-form ref="form" v-model="valid">
				<v-text-field
					v-model="oldPassword"
					:type="showOldPassword ? 'text' : 'password'"
					:label="$t('auth.oldPassword')"
					:hint="$t('auth.oldPasswordHelp')"
					outlined
					:rules="rules.password"
					rounded
					prepend-inner-icon="mdi-lock-reset"
					hide-details="auto"
					class="pb-3"
					style="max-width:400px;"
					:append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="showOldPassword = !showOldPassword"
				/>
				<v-text-field
					v-model="password"
					:type="showPassword ? 'text' : 'password'"
					:label="$t('auth.newPassword')"
					:hint="$t('auth.passwordHelp')"
					:rules="rules.password"
					required
					outlined
					rounded
					prepend-inner-icon="mdi-lock"
					hide-details="auto"
					class="pb-3"
					style="max-width:400px;"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="showPassword = !showPassword"
				/>
				<v-text-field
					v-model="passwordAgain"
					:type="showPassword ? 'text' : 'password'"
					:label="$t('auth.newPasswordAgain')"
					:rules="rules.passwordAgain"
					required
					outlined
					rounded
					prepend-inner-icon="mdi-lock"
					hide-details="auto"
					class="pb-3"
					style="max-width:400px;"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="showPassword = !showPassword"
				/>
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-btn color="primary" rounded @click="callChangePassword()">
				{{ $t('settings.changePassword') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'SettingsChangePasswordForm',
	data() {
		return {
			valid: false,
			showOldPassword: false,
			showPassword: false,
			oldPassword: '',
			password: '',
			passwordAgain: '',
			rules: {
				password: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.passwordMaxLength) || this.$t('rules.length', { length: this.passwordMaxLength })
				],
				passwordAgain: [(v) => v === this.password || this.$t('rules.passwordAgainMatch')]
			}
		}
	},
	computed: {
		passwordMaxLength() {
			return 128
		}
	},
	methods: {
		callChangePassword() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.changePassword({
				currentPassword: this.oldPassword,
				newPassword: this.password,
				newPasswordAgain: this.passwordAgain
			})
		},
		...mapActions('settings', ['changePassword'])
	}
}
</script>
